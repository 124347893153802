<template>
  <div class="card mt-4 px-8 pt-6 pb-2 mt-md-16">
    <div class="card__row1">
      {{ amount }}
    </div>
    <div class="card__row2 pt-1 pb-2">
      {{ cardInfo }}
    </div>

    <div class="float-end" v-if="chart">
      <slot />
    </div>
    <div class="" v-else>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "receivableCard",
  props: {
    cardInfo: String,
    amount: { String, Number },
    chart: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0278628);
  border-radius: 4px;
  // min-height: 140px;

  width: 100%;
  max-width: 630px;

  .card__row1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    /* hyphen Blue */

    color: #19283d;
  }

  .card__row2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f96a1;
  }

  .card__row3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f96a1;
  }

  // &:hover,
  // &:active {
  //   background: #f6f3ee;
  //   border: 1px solid #d7a47b;
  //   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0278628);
  //   border-radius: 4px;
  // }
}
</style>
